<template> 
    <div style="position: relative;">
        <!-- v-if="bk_v" -->
        <img loading="lazy"  class="d-none d-sm-block bk_v_1 no-select" src="../../assets/green_logo.png" alt="logo_green">

        
        <div class="menu-portfolio" v-if="filters_done && !variableOrderIcons"> 
            
            <div class="menu-portfolio-main-icon">
                <div class="icon d-flex align-items-center" @click="show_menu = !show_menu" :class="{ active: show_menu }"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders2 mx-auto" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                        </svg> 
                </div>
            </div>
            <div class="menu-col" v-if="show_menu">
                <div class="col"> 

                    <div v-for="c in categories" 
                        class="cat-div item text-center" 
                        :class="{'active':selected_filters.includes(c.id)}"> 
                        <div @click="filter(c.id)">
                            <img loading="lazy" width="16" class="filters no-select" :src="c.image_url" :alt="c.name">
                            <div class="cat-name-div" >
                                <div>
                                    {{c.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="theres-scroll-arrows">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                </div>
            </div> 
                
        </div>
        <div class="menu-portfolio" v-else-if="filters_done"> 

            <div class="menu-col" v-if="show_menu"> 
                <div class="col" style="display: flex;"> 
                    <div v-for="c in categories" 
                        class="cat-div-mobile item text-center" 
                        :class="{'active':selected_filters.includes(c.id)}"> <!--Menu de lado-->
                        <div @click="filter(c.id)">
                            <img loading="lazy" width="16" class="filters" :src="c.image_url" :alt="c.name">
                            <div class="cat-name-div-mobile">
                                <div>
                                    {{c.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-portfolio-main-icon-horizontal">
                <div class="icon d-flex align-items-center" @click="show_menu = !show_menu" :class="{ active: show_menu }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders2 mx-auto" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                    </svg>
                </div> 
            </div> 

        </div>
        
        <div class="container-fluid portfolio"> 
            <div class="container">
                <div class="row  py-5">
                    
                    <div class="col-12 col-sm-4 small-title bring-front">
                        {{data.title_1}}
                    </div>
                    <div class="col-8 d-none d-sm-block">
                    </div>
                    <div class="col-12 col-sm-9 title bring-front" >
                        {{data.title_2}}
                    </div>
                    <div class="col-7 d-none d-sm-block">
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100 new-light-gray-background">
            <div class="container">
                <div class="row projects projects-min-height py-5">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" style="justify-items: center;z-index: 0;    padding-bottom: calc(var(--bs-gutter-x)* .5);" v-for="p in filtered_projects" >
                        <div class="project w-100">
                            <RouterLink :to="{ name: 'Project', params: { id: p.id } }">
                                <div class="image">
                                    <img loading="lazy" :src="p.img" :alt="p.title">
                                    <div class="content d-flex">
                                        <span class="title my-auto mx-auto" style="text-transform: uppercase">{{p.title}}</span>
                                    </div>
                                </div>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
  
export default {

    data(){
        return{
            data:[],
            show_menu:false,
            projects:[],
            filters_done:null,
            selected_filters: [],
            filtered_projects: [],
            data:Object,
            bk_v:window.innerWidth <= 550 ? false : true,

            variableOrderIcons:null,
            width:window.innerWidth,
        }
    },
    
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        return { selectedLanguage, changeLanguage };
	},


    async created(){
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/portfolio'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{
            //    console.log(res);
               this.data=res.portefolio[0];
               this.projects=res.posts;
               this.categories=res.categories;

               
               const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }
            })

            this.filters_done = true ;
            this.filtered_projects=this.projects;

            this.$parent.$parent.$parent.addtoPercentage(33);
            

        
    },

    
    methods:{
        updateTabWidth() { 
            this.width = window.innerWidth;

            // Verificar se é um ecrã pequeno
            const isSmallScreen = window.matchMedia(
                "only screen and (max-width: 550px), only screen and (max-height: 550px)"
            ).matches;

            // Verificar orientação para dispositivos pequenos
            const isLandscape = window.matchMedia("(orientation: landscape)").matches;

            // Ajustar variável com base no tamanho e orientação
            this.variableOrderIcons = isSmallScreen && isLandscape;

            console.log('updateTabWidth: ' + this.variableOrderIcons);
        },

        /*
        filter(f) {
            console.log(f)
            let target = event.target.parentElement;
            target.classList.toggle('active');

            if(target.classList.value.includes('active')){
                //ADICIONAR
                this.selected_filters.push(f);
            }else{
                //REMOVER
                this.selected_filters.splice(this.selected_filters.indexOf(f),1);
            }
            
            if(this.selected_filters.length == 0){
                this.filtered_projects = this.projects;
                return;
            }            
            let projects = [];
            this.projects.forEach(p =>{
                let count = 0;
                p.categories.forEach(c =>{
                    if(this.selected_filters.includes(c.id)){
                        count++;
                    }
                });
                if(count==this.selected_filters.length)projects.push(p);
            });

            this.filtered_projects = projects;
        },*/

        filter(f) { //GPT
            console.log(f);

            // Se já estiver na lista, remove
            if (this.selected_filters.includes(f)) {
                this.selected_filters = this.selected_filters.filter(id => id !== f);
            } else {
                this.selected_filters.push(f);
            }

            if (this.selected_filters.length === 0) {
                this.filtered_projects = this.projects;
                return;
            }

            this.filtered_projects = this.projects.filter(p => 
                this.selected_filters.every(filterId => 
                    p.categories.some(c => c.id === filterId)
                )
            );
        }

    },
    mounted() {
        
        this.$nextTick(() => {
            this.updateTabWidth();
        });

        this.filtered_projects = this.projects;
        window.addEventListener("resize", this.updateTabWidth);
    },
    
    unmounted() {
        window.removeEventListener("resize", this.updateTabWidth);
    },



}
</script>

<style>

</style>