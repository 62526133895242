<template>

<vue-cookie-comply 
    :preferencesLabel="cookies.preferences_btn_txt" 
    :acceptAllLabel="cookies.accept_btn_txt"
    :preferences="preferences"
    @on-accept-all-cookies="onAccept"
    @on-save-cookie-preferences="onSavePreferences"
>
  <template v-slot:header>
    <header>
      <h3> 
        {{ cookies.title }}
      </h3>
      <p> 
        {{ cookies.text }}
      </p>
    </header>
  </template>

  <template v-slot:modal-header>
    <h3>
      {{cookies.pref_title}} 
    </h3>
  </template>
 
  <!-- <template v-slot:modal-body="{ preference }">
    <div>
      <h4>{{ preference.title }}</h4>
      <p>{{ preference.description }}</p>
      <ul>
        <li v-for="item in preference.items" :key="item.value">
          <label>
            <input
              type="checkbox"
              v-model="item.is_active"
              :disabled="item.is_required"
            />
            {{ item.label }}
          </label>
        </li>
      </ul>
    </div>
  </template> -->

  <template v-slot:modal-body="{ preference }">
    <div>
      <h4>{{ preference.title }}</h4>
      <p>{{ preference.description }}</p>
      <ul>
        <li v-for="item in preference.items" :key="item.value">
          <label>
            <n-switch
              v-model:value="item.is_active"
              :disabled="item.is_required"
            />
            {{ item.label }}
          </label>
        </li>
      </ul>
    </div>
  </template>




 
  <template v-slot:modal-footer>
    <footer>
      <div class="row">
        <div class="col-12">
          {{cookies.pref_footer}}
        </div>
        <div class="col-12 mt-2 text-center">
          <button class="cookie-comply__button close-button" @click="ClosePreferences">{{cookies.pref_close}}</button>
          <button class="cookie-comply__button save-button" @click="SavePreferences">{{cookies.pref_save}}</button>
        </div>
      </div>
    </footer>
  </template>  

</vue-cookie-comply>

  <!-- mobile -->
  <div id="all-mobile" class=" d-block d-lg-none">
    <!-- d-flex -->
    <div class="top-header container-fluid align-items-center">
      <!-- <div class="container"> --> 
  
            <nav class="navbar navbar-light social-media-mobile"> 
              <a class="icon-link1 ms-2 mt-1" v-for="i of icons.slice().reverse()" :key="i.link" :href="i.link" target="_blank"> 
                <!-- <font-awesome-icon class="icon icon-top-mobile" :icon="[i.icon.split(',')[0],i.icon.split(',')[1]]" :class="{ hidden: !(i.visivel) }"  /> -->

                <div
                  v-if="iconData[i.icon]"
                  class="outside-menu"
                  :class="{ hidden: !i.visivel }"
                  v-html="iconData[i.icon]"
                ></div>

              </a>
            </nav>
           

      <!-- </div>  -->
    </div>
    <header>
      <nav class="navbar navbar-light">
        <div class="row d-flex align-items-center" style="width: 100%;">

          <div class="col-6 col-lg-3 text-start ps-4">
            <RouterLink to="/">
              <img loading="lazy" :src="logo" alt="VIVIFY DIGITAL" class="logo">
            </RouterLink>
          </div>

          <div class="col-6 col-lg-9 text-end pe-0">
             
            <n-collapse class="collapse-btn" style="overflow-y: auto;"> 
                <div id="mobileMenu">
                  <n-collapse-item title="|||" name="1"> 
                    
                    <div class="row close-mobile-menu-row">

                      <!-- Todo -->
                      <div class="menu-mobile-social">
                        <nav class="navbar navbar-light social-media-mobile"> 
                          <a class="icon-link1 ms-2 mt-2" v-for="i of icons.slice().reverse()" :key="i.link" :href="i.link" target="_blank"> 
                            <!-- <font-awesome-icon class="icon" :icon="[i.icon.split(',')[0],i.icon.split(',')[1]]" :class="{ hidden: !(i.visivel) }"  /> -->
                            
                            <div
                              v-if="iconData[i.icon]"
                              class="inside-menu"
                              :class="{ hidden: !i.visivel }"
                              v-html="iconData[i.icon]"
                            ></div>
                          </a>
                        </nav>
                      </div>
                      <!-- Todo -->

                      <div class="col-6 text-start ps-4">
                        <RouterLink to="/">
                          <img loading="lazy" :src="logo_white" alt="VIVIFY DIGITAL" class="logo">
                        </RouterLink>
                      </div>

                      <div class="col-6">
                        <div class="close-x-div" @click="closeMobileMenu"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="34px" height="34px" fill="currentColor" class="bi bi-x-lg close-mobile-menu" viewBox="0 0 16 16">
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg> 
                        </div>
                      </div>
                       

                    </div>
                    <div class="row" style="margin: 0;"> 
                      <div class="col-12 mt-5">
                        <n-menu :options="combinedMenuOptions" mode="horizontal" style="display: flex; justify-content: flex-end;">
                        </n-menu> 
                      </div>
                    </div> 

                    <div class="row mobile-langs-row"> 
                      <div class="col-12"> 
                        <div class="mobile-langs text-center">
                          <template v-for="(lang, index) in languages[0]" :key="lang">
                            <span
                              @click="closeMobileMenu(); changeLanguage(lang)"
                              :class="{ active: lang === currentLanguage }"
                            >
                              {{ languages[1][index] }}
                            </span>
                            <span v-if="index < languages[0].length - 1"> | </span>
                          </template>
                        </div>

                        
                      </div>
                    </div>

                  </n-collapse-item>
                </div>
            </n-collapse>


          </div>

        </div>
      </nav>
    </header>

  </div>

  
  <!-- PC -->
  <div class="d-none d-lg-block ">

    <div class="top-header">
      <nav class="navbar navbar-expand-lg navbar-light container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav"> 
          <n-menu :options="topMenuOptions" mode="horizontal" class="topbar" style="justify-content: right;">
          </n-menu>
        </div>
        <a class="icon-link1 mx-1" v-for="i of icons" :href="i.link" target="_blank">
          <font-awesome-icon class="icon" :icon="[i.icon.split(',')[0],i.icon.split(',')[1]]" :class="{ hidden: !(i.visivel) }"  />
        </a>
 
        <div class="language-selector change-lang ms-4">
                  <!-- O select é escondido visualmente -->
                  <select class="lang-select" v-model="language" @change="changeLang">
                      <option v-for="(l, key) in languages" :value="l" :key="key">
                          {{ l }}
                      </option>
                  </select>

                  <!-- Dropdown -->
                  <div id="custom-select" @click="toggleDropdown">
                      <span>{{ selectedLanguage.lang }}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16" style="vertical-align: -0.5px; stroke-width: 1.5;">
                          <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" style="stroke:currentColor;" />
                      </svg> 

                  </div>

                  <!-- Lista de idiomas --> 
                  <ul v-if="dropdownOpen" class="custom-options">
                    <li v-for="l in filteredLanguages" :key="l" @click="changeLanguage(l)">
                      <span>{{ l }}</span>
                    </li>
                  </ul>
 
              </div>

      </nav>
    </div>
    <header class="container" style="z-index: 4; padding-right: 0;">
      <nav class="navbar navbar-expand-lg navbar-light container-fluid" style="display: block;">
        <div class="row" >
<!-- style="width: 100%;" -->
          <div class="col-6 col-lg-3 text-start">
            <RouterLink to="/">
              <img loading="lazy" :src="logo" alt="VIVIFY DIGITAL" class="logo">
            </RouterLink>
          </div>
 <!-- pe-0 -->
          <div class="col-6 col-lg-9 text-end" style="display: flex;">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse fix-padding-right" id="navbarNav"  v-if="!removeNav"> 

              <n-menu :options="menuOptions" mode="horizontal" style="justify-content: right;--n-item-text-color-hover-horizontal:#868688 !important;--n-item-icon-color-hover-horizontal:#868688 !important;"> 
              </n-menu>
 
            </div>
          </div>

        </div>
      </nav>
    </header>
  </div>
</template>




<script>

import {  h,computed } from "vue";
import { NMenu } from 'naive-ui';
import { RouterLink } from "vue-router";
import { useLanguageStore } from '../stores/language';
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch)

export default {
  components:{NMenu},

  data(){
    return{
      menuOptions:[],
      topMenuOptions:[],
      combinedMenuOptions: [],
      icons:[],
      removeNav:false, 
      show_menu_mobile:false,
      logo:'',
      logo_white:'',

      // languages:['pt', 'en'],
      languages:[['pt', 'en', 'es'], ['Português', 'Inglês', 'Espanhol']], 
      currentLanguage: JSON.parse(localStorage.getItem('globalLang') || '{}').lang || 'pt',

      dropdownOpen: false,

      cookies: [],

      preferences: [],

      iconData: {
        'fab,facebook': '<svg class="icon icon-top-mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>',
        'fab,medium': '<svg class="icon icon-top-mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M180.5 74.3C80.8 74.3 0 155.6 0 256S80.8 437.7 180.5 437.7 361 356.4 361 256 280.2 74.3 180.5 74.3zm288.3 10.6c-49.8 0-90.2 76.6-90.2 171.1s40.4 171.1 90.3 171.1 90.3-76.6 90.3-171.1H559C559 161.5 518.6 84.9 468.8 84.9zm139.5 17.8c-17.5 0-31.7 68.6-31.7 153.3s14.2 153.3 31.7 153.3S640 340.6 640 256C640 171.4 625.8 102.7 608.3 102.7z"/></svg>',
        'fab,instagram': '<svg class="icon icon-top-mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>',
        'fab,linkedin-in': '<svg class="icon icon-top-mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>',
      }, 
    }
  },
  

  computed: {
    filteredLanguages() {
      return this.languages[0].filter(l => 
        (l || '').toString().trim().toLowerCase() !== (this.currentLanguage || '').toString().trim().toLowerCase()
      );
    }
  },

  setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
    const changeLanguage = (lang) => {
      languageStore.setLanguage(lang);
    };
		return { selectedLanguage, changeLanguage };
	},
  methods: {

    onAccept() {
      // console.log('User has accepted all cookies')
    },
 
    onSavePreferences() {
      // console.log('User has saved preferences')
    },

    SavePreferences() { 
      
      this.$emit('on-save-cookie-preferences', this.preferences); 
      localStorage.setItem('cookie-comply', JSON.stringify(this.preferences));

      // Ocultar a modal e a popup de cookies
      document.querySelector('.cookie-comply').style.display = 'none';
      document.querySelector('.cookie-comply__modal').style.display = 'none';
    },

    ClosePreferences() {
      document.querySelector('.cookie-comply__modal').style.display = 'none';
    
      // set overflow: auto; to body 
      document.body.style.overflow = 'auto';
    },








    
    // Se o clique não for dentro de nenhum dos selects, fecha o dropdown
    handleClickOutside(event) {  
      try{
        const customSelect1 = $('#custom-select')[0];
        const customSelect2 = $('#custom-select-mobile')[0];
        
        const isClickInsideSelect1 = customSelect1 && customSelect1.contains(event.target);
        const isClickInsideSelect2 = customSelect2 && customSelect2.contains(event.target);
  
        if (!isClickInsideSelect1 && !isClickInsideSelect2) { 
            this.dropdownOpen = false;  
        } else { 
        }
      }catch (e) {
      }
      

    },

    toggleDropdown() { 
      this.dropdownOpen = !this.dropdownOpen; 
    },
    
    scrollLock() {  
      const mobileMenu = document.getElementById('all-mobile');
      
      if (mobileMenu) {  
          const collapseHeader = mobileMenu.querySelector('.n-collapse-item__header-main');
          
          if (collapseHeader) { 
            collapseHeader.addEventListener('click', () => { 
              window.scrollTo(0, 0);

              this.show_menu_mobile = !this.show_menu_mobile;

              const main_body = document.querySelector('.main_body');
              const footer = document.querySelector('footer');

              // Função para prevenir o scroll
              function preventScroll(event) {
                event.preventDefault();
                event.stopPropagation();
                this.scrollTop = 0; // Mantém a posição de scroll no topo
              }

              if (main_body) { 

                if (this.show_menu_mobile) {
                  // Bloquear o scroll no <body> e na `.main_body`
                  document.body.style.overflow = 'hidden';
                  main_body.addEventListener('scroll', preventScroll);

                  // Ocultar o footer
                  if (footer) {
                    footer.style.display = 'none';
                  }

                  // this.show_menu_mobile = false;
                } else {
                  // Permitir novamente o scroll
                  document.body.style.overflow = 'auto';
                  main_body.removeEventListener('scroll', preventScroll);

                  // Exibir o footer
                  if (footer) {
                    footer.style.display = 'block';
                  }
                  // this.show_menu_mobile = true;
                }
              }
 
            });
          }
      }  
    },


    toggleMobileMenuOff() { 
      this.show_menu_mobile = false; 
    },

    closeMobileMenu() { 
      console.log('closeMobileMenu');

        const mobileMenu = document.getElementById('mobileMenu');
        
        if (mobileMenu) { 
            const collapseHeader = mobileMenu.querySelector('.n-collapse-item__header-main');
              
            if (collapseHeader) {
                collapseHeader.click();
            } else {
                // console.warn('Elemento .n-collapse-item__header não encontrado dentro de #mobileMenu');
            }
        } else {
            // console.warn('Elemento #mobileMenu não encontrado');
        }
    },
 
  },

  

  mounted() { 
    document.addEventListener('click', this.handleClickOutside.bind(this));


    this.handleClick = (event) => {
      const button = event.target;
      if (button.classList.contains('cookie-comply__button') && button.classList.length === 1) {
        const modal = document.querySelector('.cookie-comply__modal');
        if (modal) {
          modal.style.display = 'table';
          // set overflow: hidden; to body 
          document.body.style.overflow = 'hidden';
        }
      }
    };

    document.addEventListener('click', this.handleClick);

  },

  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() { 
    window.removeEventListener('inertia:navigate', this.afterNavigate); 

    document.removeEventListener('click', this.handleClickOutside); 
 
    document.removeEventListener('click', this.handleClick);
  },

  async created(){ 

    await fetch('https://cms.vivify.pt/wp-json/options/header'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
      .then(res => res.json()) 
      .then(res =>{
        this.icons=res.socials;
        this.logo = res.logo;
        this.logo_white = res.logo_white;
        for(let aux of res.menu){
          this.menuOptions.push(
            {
              label: () => 
                [h(RouterLink,{to: {path: aux.link},},{ default: () => [aux.nome,h('div',{'class':'menu-active'})]})],
                key: aux.nome,
            }
          ,);
 

          this.combinedMenuOptions.push({
            label: () => {
              // Verifica se o link é externo
              const isExternalLink = /^(http|https):\/\//.test(aux.link);  
              
              // console.log('isExternalLink');
              if (isExternalLink) { 

                `<a href="${aux.link}a" target="_blank" rel="noopener noreferrer">
                  ${aux.nome}
                  <div class="menu-active"></div>
                </a>`
                
              } else { 
                // Se for link interno, retorna o RouterLink
                return [
                  h(RouterLink, { to: { path: aux.link } }, { 
                    default: () => [
                      aux.nome, 
                      h('div', { class: 'menu-active' })
                    ]
                  })
                ];
              }
            },
            key: aux.nome,
          });

          
        }

        for(let aux of res.top_bar){
          this.topMenuOptions.push({
            label: () => 
              aux.new_tab 
                ? h('a', 
                    { 
                      href: aux.link,
                      target: '_blank', 
                      rel: 'noopener noreferrer' ,
                    }, 
                    aux.name
                  )
                : h(RouterLink, 
                    { 
                      to: aux.link,
                      class: 'top-bar-a'
                    }, 
                    { default: () => aux.name }
                  ),
              key: aux.name,
          });
 
          
          this.combinedMenuOptions.push({
            label: () => {
              // Verifica se o link é externo
              const isExternalLink = /^(http|https):\/\//.test(aux.link);  
              
              // console.log('isExternalLink');
              if (isExternalLink) { 
                // Se for link externo, retorna uma tag <a>
                
                return [
                  h('a', { 
                    href: aux.link, 
                    target: '_blank', 
                    rel: 'noopener noreferrer' 
                  }, [
                    aux.name, 
                    h('div', { class: 'menu-active' })
                  ])
                ];
                 
              } else { 
                // Se for link interno, retorna o RouterLink
                return [
                  h(RouterLink, { to: { path: aux.link } }, { 
                    default: () => [
                      aux.name, 
                      h('div', { class: 'menu-active' })
                    ]
                  })
                ];
              }
            },
            key: aux.name,
          });
 

        }
  

        this.$parent.$parent.addtoPercentage(33);
        this.$parent.headerflag=true; 

        
        this.cookies=res.cookies[0]; 

        this.preferences = res.cookies[0]['preferences'];
 
        
      })  

      this.scrollLock();
  }
}
</script>

<style>

</style>