<template>   

    <div class="inside-project">
        <div class="portfolio"> 
            <div class="container">
                <div class="row"> 
                <div class="col-12 small-title mt-5">
                    <span class="mt-2" v-for="(t, index) in project.categories">
                        <span v-if="t!=3 && checkIfExists(t)"> 
                        {{ getCateName(t) }}<span v-if="index < project.categories.length - 1"><b> · </b></span>
                        </span>
                    </span>
                </div>
                    <div class="col-12  title  mb-5" >
                        {{ project.title }}
                    </div>
                </div> 
            </div>
        </div>


        <!-- TODO -> meter tipo de letra e centrar imagens -->
        <div class="container mt-5 mb-5 project-info" >
            <div class="row">
                <div class="col-12" >
                    <div v-html="project.content"></div>
                </div> 
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12">
                <div class="blue-button">
                    <RouterLink to="/portfolio">
                        {{data_blogs_2.go_back_to_portfolio}}
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>

</template>
  
<script>

import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
  
export default {
    data(){
        return{
            data:[],
            show_menu:false,
            project:Object,
            filters:[],
            data_blogs_2:[],
        }
    },

    
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},


    async created(){
        this.$parent.$parent.$parent.resetFlag();

        await fetch('https://cms.vivify.pt/wp-json/wp/v2/posts/'+this.$route.params.id+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{
                this.project = {
                    id:res.id,
                    title:res.title.rendered,
                    categories:res.categories,
                    // image:res._embedded['wp:featuredmedia'][0].source_url,
                    content:res.content.rendered,
                }
            })

        await fetch('https://cms.vivify.pt/wp-json/wp/v2/categories?parent=4')
            .then(res => res.json()) 
            .then(res =>{
                res.forEach(r => {
                    this.filters.push({
                        id:r.id,
                        name:r.name,
                    })
                })
            })
                

        await fetch('https://cms.vivify.pt/wp-json/options/project'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{  
                this.data_blogs_2=res['project_page'][0];
                // console.log(this.data_blogs_2);
                
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data_blogs_2.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data_blogs_2.metakeywords || ''); 
                }
            })

        this.$parent.$parent.$parent.addtoPercentage(33);
        
    },
    methods:{
        checkIfExists(id){
            for(let filter of this.filters){
                if(filter.id==id){
                    return true;
                }
            }
            return false;
        },
        getCateName(id){
            for(let filter of this.filters){
                if(filter.id==id){
                    return filter.name;
                }
            }
        },
       
    }
}
</script>

<style>

</style>