<template> 

        <div class="row challenge no-margin" :style="{ 'background-image': 'url(' + challenge_background_image + ')' }">
            <div class="box">
                <div class="container" style="height: 100%;"> 
                    <div class="row" style="height: 100%;"> 
 
                        <!-- top-margin-2em -->
                        <div class="col-12 col-xl-10 vertical-center">
                            <div>
                                <span class="title">{{title}}</span>
                                <br>
                                <div class="challenge-text-div">
                                    <span>{{text}}</span>
                                </div>
                            </div>
                        </div> 
            

                        <div class="col-12 col-xl-2 my-3">
                            <div class="blue-button-out">
                                <div class="blue-button">
                                    <RouterLink to="/challenge">  
                                        {{button}}
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> 
        </div> 
  
</template>
  
<script> 
  	export default {
		
		props: ['title', 'text', 'button', 'challenge_background_image'],

		data(){ 
			
		}, 
	}
</script>

<style>

</style>